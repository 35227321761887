import axios from 'axios';

export enum DudaCollectionNames {
  BranchPageSettings = 'Branch',
  AdvisorPageSettings = 'Pinnacle Advisors'
}

export interface DudaReturn<T> {
  name: string;
  values: {
    id: string;
    page_item_url: string;
    data: T;
  }[];
}

export enum PageSliceOptions {
  On = 'On',
  Off = 'Off'
}

export enum BranchTemplateOptions {
  TeamPhoto = 'Team Photo',
  NoTeamPhoto = 'No Team Photo'
}

export interface BranchPageSettingsData {
  'Branch Name': string;
  'Branch Template': BranchTemplateOptions[];
  'Custom Content': PageSliceOptions[];
  'Latest Videos': PageSliceOptions[];
  'Podcast Slice': PageSliceOptions[];
}
export enum AdvisorSettingColumns {
  TeamMemberName = 'Team Member Name (Advisor)',
  LatestVideo = 'Latest Videos Slice',
  CustomContent = 'Custom Content Slice',
  Podcasts = 'Podcasts Slice',
  AdvisorStream = 'Advisor Stream Slice'
}
export interface AdvisorPageSettingsData {
  [AdvisorSettingColumns.TeamMemberName]?: string;
  [AdvisorSettingColumns.LatestVideo]?: PageSliceOptions[];
  [AdvisorSettingColumns.CustomContent]?: PageSliceOptions[];
  [AdvisorSettingColumns.Podcasts]?: PageSliceOptions[];
  [AdvisorSettingColumns.AdvisorStream]?: PageSliceOptions[];
}

const getCollection = async (
  siteId: string,
  collectionName: DudaCollectionNames,
  slug: string
): Promise<BranchPageSettingsData | AdvisorPageSettingsData | null> => {
  return axios({
    method: 'get',
    url: `https://efhr3zjik0.execute-api.us-east-1.amazonaws.com/prod/collection?siteName=${siteId}&collectionName=${collectionName}`
  })
    .then((res) => {
      if (!res?.data) return null;
      const result = res.data as DudaReturn<
        BranchPageSettingsData | AdvisorPageSettingsData
      >;

      const resultForRow = result.values.filter(
        (value) => value.page_item_url === slug
      )[0].data;
      return resultForRow ?? null;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export default getCollection;
