import styled from 'styled-components';
import { YouTubePlaylist } from '../YoutubePlayList/YouTubePlaylist';

const FeaturedVideosSlice: React.FC<{ src: string }> = ({ src }) => {
  const extractPlaylistId = () => {
    const regex = /[?&]list=([^#&?]+)/;
    const match = src.match(regex);
    return match ? match[1] : src;
  };

  return (
    <Container>
      <ContainerInner>
        <div style={{ marginBottom: '40px' }}>
          <h2 style={{ fontWeight: 275 }}>Latest Videos</h2>
          <YouTubePlaylist
            apiKey="AIzaSyBdm9BOgl3xhojKWuM8TIn6LWQBY2H_GEg"
            playlistId={extractPlaylistId()}
          />
        </div>
      </ContainerInner>
    </Container>
  );
};

const Container = styled.div`
  background-color: #f5f8f9;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 10px;
  h2 {
    font-size: 44px;
    font-weight: 300;
    font-family: Poppins;
    margin-top: 0;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
  p {
    opacity: 80%;
  }
`;

const ContainerInner = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export default FeaturedVideosSlice;
