import { Form, Formik } from 'formik';
import { TeamMemberFormValues } from 'models/branch';
import React from 'react';
import {
  Divider,
  FormSection,
  FormWrapper,
  LoadingContainer,
  Photo,
  PhotoContainer,
  PhotoDetails,
  SectionHeader,
  Tooltip
} from '../StyledComponents';
import {
  FormikCheckField,
  FormikRadioGroup,
  FormikTextArea,
  FormikTextField
} from 'components/Form';
import { Box, Grid, Typography } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import Button from '../Button';
import { enqueueSnackbar } from 'notistack';
import image from 'assets/images/placeholder.svg';
import { advisorType } from 'utilities/CheckboxLists';
import _ from 'lodash';
import postTeamMemberDetails from 'queries/postTeamMemberDetails';
import { updateTeamMemberSchema } from 'constants/schemas';

interface TeamMemberEditFormProps {
  initialValues: TeamMemberFormValues;
  activeStep: number;
  totalSteps: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  locked: boolean;
  setLocked: React.Dispatch<React.SetStateAction<boolean>>;
}

const TeamMemberEditForm: React.FC<TeamMemberEditFormProps> = ({
  initialValues,
  activeStep,
  totalSteps,
  setActiveStep,
  locked,
  setLocked
}) => {
  const [loading, setLoading] = React.useState(false);
  const last_step = activeStep === totalSteps;

  const handleNext = () => {
    if (last_step) {
      setLocked(true);
      enqueueSnackbar('Your review is now completed', {
        variant: 'success'
      });
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const submitChanges = async (values: TeamMemberFormValues) => {
    if (!values.id) return;

    // if no changes, skip submitting and go to next step
    if (_.isEqual(values, initialValues)) {
      handleNext();
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, photo, ...rest } = values;
    setLoading(true);

    try {
      await postTeamMemberDetails({ id, teamMemberEdits: rest });
      enqueueSnackbar('Updates have been submitted successfully', {
        variant: 'success'
      });
      setLoading(false);
      handleNext();
    } catch (err) {
      console.log('error: ', err);
      enqueueSnackbar(
        'Something went wrong, please screenshot your webpage and contact your administrator',
        {
          variant: 'error'
        }
      );
      setLoading(false);
    }
  };

  const first_name = initialValues?.name?.split(' ')[0];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitChanges}
      validationSchema={updateTeamMemberSchema}
      enableReinitialize
    >
      {({ values, isValid }) => (
        <Box sx={{ width: '100%' }}>
          <Form>
            <SectionHeader>{first_name}'s Information</SectionHeader>
            <FormWrapper>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <PhotoContainer style={{ flexDirection: 'column' }}>
                    <Photo src={values.photo ?? image} alt="" />
                    <PhotoDetails>
                      <Tooltip style={{ fontSize: '14px' }}>
                        If you would like your photo modified, please submit a
                        note at the bottom of this form and your administrator
                        will contact you
                      </Tooltip>
                    </PhotoDetails>
                  </PhotoContainer>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormikTextField
                    label={`${first_name}'s Website Link`}
                    name="locatorSlug"
                    placeholder="Website Link"
                    disabled
                  />
                  <Box mb={4} />
                  <FormikTextField
                    label={`${first_name}'s Name`}
                    name="name"
                    placeholder="Name"
                    disabled={locked}
                  />
                  <Box mb={4} />
                  <FormikRadioGroup
                    name="typeOfAdvisor"
                    label="Type of Advisor"
                    options={advisorType.map((item) => ({
                      value: item.id,
                      label: item.label
                    }))}
                    disabled={locked}
                  />
                </Grid>
              </Grid>
              <FormSection style={{ rowGap: '45px' }}>
                <FormikTextField
                  label={`${first_name}'s Title`}
                  name="title"
                  placeholder="Title"
                  disabled={locked}
                />
                <FormikTextField
                  label={`${first_name}'s Designation`}
                  name="designation"
                  placeholder="Designation"
                  disabled={locked}
                />
                <FormikTextField
                  label={`${first_name}'s Email (used to direct contact form to email)`}
                  name="email"
                  placeholder="Email"
                  disabled={locked}
                />
                <FormikTextField
                  label={`${first_name}'s Facebook Link`}
                  name="facebook"
                  placeholder="Facebook"
                  disabled={locked}
                />
                <FormikTextField
                  label={`${first_name}'s LinkedIn Link`}
                  name="linkedIn"
                  placeholder="LinkedIn"
                  disabled={locked}
                />
                <Box style={{ gridColumn: 'span 2' }}>
                  <FormikTextField
                    label="Book an appointment link (optional)"
                    name="bookAnAppointment"
                    placeholder="Link"
                    disabled={locked}
                  />
                </Box>
                <Box style={{ gridColumn: 'span 2' }}>
                  <FormikTextField
                    label={`${first_name}'s Expertise`}
                    name="yourExpertise"
                    placeholder="Expertise 1, Expertise 2, Expertise 3, Expertise 4"
                    description="Please separate each expertise by a comma"
                    disabled={locked}
                  />
                </Box>
                <Box style={{ gridColumn: 'span 2' }}>
                  <FormikTextArea
                    label={`${first_name}'s Bio`}
                    name="description"
                    placeholder="Bio here..."
                    style={{ height: '100px' }}
                    disabled={locked}
                  />
                </Box>
                <Box
                  style={{
                    gridColumn: 'span 2',
                    display: 'flex'
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={1} sm={4}>
                      <FormikCheckField
                        name="hasPodcast"
                        description={`Does ${first_name} have a podcast to feature?`}
                        label="Yes"
                        disabled={locked}
                        onChange={(e) => {
                          console.log(e);
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} sm={4}>
                      <FormikCheckField
                        name="hasVideoLibrary"
                        description={`Does ${first_name} have a video library to feature?`}
                        label="Yes"
                        disabled={locked}
                      />
                    </Grid>
                    <Grid item xs={1} sm={4}>
                      <FormikCheckField
                        name="hasStreamArticles"
                        description={`Does ${first_name} have Advisor Stream articles to feature?`}
                        label="Yes"
                        disabled={locked}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box style={{ gridColumn: 'span 2' }}>
                  <Divider />
                  <FormikTextArea
                    label={`${first_name}'s Comments or Change Requests`}
                    name="comments"
                    placeholder="Comments here..."
                    style={{ height: '100px' }}
                    disabled={locked}
                  />
                </Box>
              </FormSection>
              <Box
                sx={{
                  gridColumn: 'span 2',
                  display: 'flex',
                  justifyContent: 'space-between',
                  pt: '20px'
                }}
              >
                <Box>
                  <Typography>
                    {activeStep} of {totalSteps}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Button
                    text="Back"
                    type="button"
                    handleClick={handleBack}
                    theme="white"
                    style={{
                      marginRight: '10px',
                      border: 'none',
                      color: '#000'
                    }}
                  />
                  {loading ? (
                    <LoadingContainer>
                      <ClipLoader
                        color={'#FFFFFF'}
                        loading={loading}
                        size={25}
                      />
                    </LoadingContainer>
                  ) : (
                    <Box>
                      <Button
                        disabled={last_step && locked}
                        type="submit"
                        text={last_step ? 'Submit & Approve' : 'Next'}
                        theme={last_step ? undefined : 'white'}
                        style={
                          last_step
                            ? {
                                color: '#FFFFFF'
                              }
                            : {
                                border: 'none',
                                color: '#000',
                                backgroundColor: '#DBE3E0'
                              }
                        }
                      />
                      {!isValid && (
                        <Typography
                          sx={{ fontSize: '10px', fontStyle: 'italic', mt: 1 }}
                        >
                          *Please check errors in the form
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </FormWrapper>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default TeamMemberEditForm;
