import React from 'react';
import { buttonTheme } from 'utilities/theme';
import styled from 'styled-components';

const Button: React.FC<{
  uniqueId?: string;
  text: string;
  theme?: string | undefined;
  type: undefined | 'button' | 'submit' | 'reset';
  handleClick?: () => void;
  handleKeyDown?: (e: any) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
}> = ({
  text,
  theme,
  type,
  handleClick,
  handleKeyDown,
  disabled,
  uniqueId,
  style,
  icon
}) => {
  return (
    <Link
      id={uniqueId}
      disabled={disabled}
      type={type}
      onKeyDown={handleKeyDown}
      onClick={disabled ? undefined : handleClick}
      theme={theme}
      style={style}
    >
      {icon}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%'
        }}
      >
        {text}
      </div>
    </Link>
  );
};

const Link = styled.button<{ theme: string }>`
  display: flex;
  align-items: center;
  font-family: Gill Sans;
  font-weight: 600;
  font-size: 16px;
  height: 54px;
  padding: 0px 8px 0px 8px;
  .
  &:focus {
    outline: 2px dotted #000000;
  }
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  },
  ${(props) => (props ? buttonTheme : 'unset')};
`;

export default Button;
