import { Box } from '@mui/material';
import BranchEditForm from 'components/General/branch/BranchEditForm';
import EditBranchStepper from 'components/General/branch/EditBranchStepper';
import TeamMemberEditForm from 'components/General/branch/TeamMemberEditForm';
import { Container, Subtext, Title } from 'components/General/StyledComponents';
import { Branch } from 'models/branch';
import { enqueueSnackbar } from 'notistack';
import collectSpecificBranch from 'queries/collectBranchDetails';

import queryString from 'query-string';
import React, { useState } from 'react';
import {
  getBranchDetailsInitialValues,
  getTeamMemberInitialValues
} from 'utilities/branch';

const EditBranchScreen: React.FC = () => {
  const urlVariables = queryString.parse(window.location.search);
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [errorBranch, setErrorBranch] = useState('');
  const [locked, setLocked] = React.useState(false);

  const [initialValues, setInitialValues] = useState<Branch>({
    branchDetails: {},
    teamMembers: [],
    consultants: []
  });

  React.useEffect(() => {
    setActiveStep(1);
  }, []);

  React.useEffect(() => {
    if (activeStep === 0) return;
    collectBranch();
  }, [activeStep]);

  const collectBranch = async () => {
    setLoading(true);
    try {
      const branch = await collectSpecificBranch(
        urlVariables.branchSlug as string
      );

      if (branch) {
        const branchDetails = getBranchDetailsInitialValues(
          branch.branchDetails
        );
        const teamMembers = branch?.teamMembers.map((teamMember) =>
          getTeamMemberInitialValues(teamMember)
        );

        setInitialValues({ teamMembers, branchDetails, consultants: [] });
      }
    } catch (err) {
      const error = err as Error;
      enqueueSnackbar(error.message, { variant: 'error' });
      setErrorBranch(error.message);
    } finally {
      setLoading(false);
    }
  };

  const total_steps = React.useMemo(
    // + 1 for branch form
    () => initialValues.teamMembers.length + 1,
    [initialValues.teamMembers]
  );

  const expertise = React.useMemo(() => {
    return initialValues.teamMembers
      .map((teamMember) => teamMember.yourExpertise)
      .filter((expertise) => expertise && expertise.length > 0)
      .join(', ');
  }, [initialValues.teamMembers]);

  const advisorTeams = React.useMemo(() => {
    return initialValues.teamMembers
      .map((teamMember) => teamMember.name)
      .join(', ');
  }, [initialValues.teamMembers]);

  const error = (
    <Container>
      <Box sx={{ my: 3 }}>Something went wrong: {errorBranch}</Box>
    </Container>
  );

  const loadingScreen = <Box py="100px" />;

  if (loading) return loadingScreen;
  if (errorBranch) return error;

  return (
    <Container style={{ paddingTop: '150px' }}>
      <Title>{`Welcome ${initialValues.branchDetails.branchName ?? ''}`}</Title>
      <Subtext>
        {
          'Please take a few minute to review to ensure your branch’s and team’s information is accurate. You can make your edits directly on this form and click next to advance through your team’s information. Once you have looked through every team member’s information, please click “Approve & Submit” at the end to let us know you have finished reviewing.'
        }
      </Subtext>
      <EditBranchStepper
        activeStep={activeStep}
        setActive={setActiveStep}
        total_steps={total_steps}
        disabled={loading}
      />
      {activeStep === 1 && (
        <BranchEditForm
          initialValues={{
            ...initialValues.branchDetails,
            expertise,
            advisorTeams
          }}
          activeStep={activeStep}
          totalSteps={total_steps}
          setActiveStep={setActiveStep}
          locked={locked}
          setLocked={setLocked}
        />
      )}
      {initialValues.teamMembers.map(
        (teamMember, i) =>
          activeStep === i + 2 && (
            <TeamMemberEditForm
              key={teamMember.name}
              initialValues={teamMember}
              activeStep={activeStep}
              totalSteps={total_steps}
              setActiveStep={setActiveStep}
              locked={locked}
              setLocked={setLocked}
            />
          )
      )}
    </Container>
  );
};

export default EditBranchScreen;
