import {
  BranchDetails,
  BranchEdits,
  BranchFormValues,
  TeamMemberDetails,
  TeamMemberFormValues
} from 'models/branch';
import { provinces_codes } from './CheckboxLists';

const getProvinceCode = (province?: string) => {
  // if province is not one of the codes in provinces, return empty string

  if (province && provinces_codes.includes(province)) {
    return province;
  }
  return '';
};

export const getBranchDetailsInitialValues = (
  branch: BranchDetails
): BranchFormValues => {
  const location = {
    address: branch.branchEdits?.address ?? branch.branchLocation?.address,
    address2: branch.branchEdits?.address2 ?? branch.branchLocation?.address2,
    city: branch.branchEdits?.city ?? branch.branchLocation?.city,
    postalCode:
      branch.branchEdits?.postalCode ?? branch.branchLocation?.postalCode,
    province: getProvinceCode(
      branch.branchEdits?.province ?? branch.branchLocation?.province
    ),
    officePhone:
      branch.branchEdits?.officePhone ?? branch.branchLocation?.officePhone,
    secondaryOfficePhone:
      branch.branchEdits?.secondaryOfficePhone ??
      branch.branchLocation?.secondaryOfficePhone
  };
  const officeHours = JSON.stringify(
    branch.branchEdits?.officeHours ??
      branch.branchLocation?.officeHours?.[0] ??
      {}
  );
  return {
    branchName: branch.branchEdits?.branchName ?? branch.branchName,
    branchLocation: location,
    branchSlug: branch.branchEdits?.branchSlug ?? branch.branchSlug,
    branchIntro: branch.branchEdits?.branchIntro ?? branch.branchIntro,
    branchComments: branch.branchEdits?.branchComments,
    branchPhotoTemplate: branch.branchEdits?.branchPhotoTemplate,
    officeHours,
    id: branch.id
  };
};

export const getMappedBranchDetailsForPost = (
  values: BranchFormValues
): BranchEdits => ({
  branchName: values.branchName,
  branchSlug: values.branchSlug,
  officePhone: values.branchLocation?.officePhone,
  secondaryOfficePhone: values.branchLocation?.secondaryOfficePhone,
  branchIntro: values.branchIntro,
  address: values.branchLocation?.address,
  address2: values.branchLocation?.address2,
  city: values.branchLocation?.city,
  postalCode: values.branchLocation?.postalCode,
  province: values.branchLocation?.province,
  officeHours: JSON.parse(values.officeHours ?? ''),
  branchPhotoTemplate: values.branchPhotoTemplate,
  branchComments: values.branchComments
});

export const getTeamMemberInitialValues = (
  teamMember: TeamMemberDetails
): TeamMemberFormValues => ({
  name: teamMember.teamMemberEdits?.name ?? teamMember.name,
  title: teamMember.teamMemberEdits?.title ?? teamMember.title,
  designation:
    teamMember.teamMemberEdits?.designation ?? teamMember.designation,
  description:
    teamMember.teamMemberEdits?.description ?? teamMember.description,
  typeOfAdvisor:
    teamMember.teamMemberEdits?.typeOfAdvisor ?? teamMember.typeOfAdvisor,
  email: teamMember.teamMemberEdits?.email ?? teamMember.email,
  linkedIn: teamMember.teamMemberEdits?.linkedIn ?? teamMember.linkedIn,
  facebook: teamMember.teamMemberEdits?.facebook ?? teamMember.facebook,
  bookAnAppointment:
    teamMember.teamMemberEdits?.bookAnAppointment ??
    teamMember.bookAnAppointment,
  yourExpertise:
    teamMember.teamMemberEdits?.yourExpertise ?? teamMember.yourExpertise,
  photo: teamMember.photo,
  hasPodcast: teamMember.teamMemberEdits?.hasPodcast,
  hasVideoLibrary: teamMember.teamMemberEdits?.hasVideoLibrary,
  hasStreamArticles: teamMember.teamMemberEdits?.hasStreamArticles,
  locatorSlug: teamMember.locatorSlug,
  enableAdvisorLocator: teamMember.enableAdvisorLocator,
  comments: teamMember.teamMemberEdits?.comments,
  id: teamMember.id
});
