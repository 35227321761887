import * as React from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Dispatch, SetStateAction, FC } from 'react';
import { Box, Typography } from '@mui/material';

interface EditBranchStepperProps {
  total_steps: number;
  activeStep: number;
  setActive: Dispatch<SetStateAction<number>>;
  disabled?: boolean;
}

const EditBranchStepper: FC<EditBranchStepperProps> = ({
  activeStep,
  setActive,
  total_steps,
  disabled
}) => {
  const handleNext = () => {
    setActive((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActive((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', my: 6, flexWrap: 'wrap' }}>
      <MobileStepper
        variant="progress"
        // 1-based index
        steps={total_steps + 1}
        position="static"
        activeStep={activeStep}
        sx={{ flexGrow: 1 }}
        nextButton={<></>}
        backButton={<></>}
        LinearProgressProps={{ color: 'success', style: { width: '100%' } }}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', px: 2 }}>
        <Typography sx={{}}>
          {activeStep} of {total_steps}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Button
          size="small"
          sx={{ minWidth: '24px' }}
          onClick={handleBack}
          disabled={activeStep === 1 || disabled}
          color="success"
        >
          <KeyboardArrowLeft />
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          size="small"
          sx={{ minWidth: '24px' }}
          onClick={handleNext}
          disabled={activeStep === total_steps || disabled}
          color="success"
        >
          <KeyboardArrowRight />
        </Button>
      </Box>
    </Box>
  );
};

export default EditBranchStepper;
