import { Box } from '@mui/material';
import { AdvisorSectionDescription, SectionHeader } from '../StyledComponents';
import BranchAdvisorCardTeamPhoto from './BranchAdvisorCardTeamPhoto';
import { TeamMemberDetails } from 'models/branch';
import BranchConsultantCardTeamPhoto from './BranchConsultantCardTeamPhoto';
import React from 'react';

const TeamMembersGrid = ({
  advisors,
  title,
  description,
  isConsultants
}: {
  advisors: TeamMemberDetails[];
  title: React.ReactNode;
  description?: string;
  isConsultants?: boolean;
}) => {
  return (
    <Box style={{ marginTop: 50 }}>
      <SectionHeader
        style={{
          justifyContent: 'center',
          marginTop: 0,
          paddingBottom: 0
        }}
      >
        {title}
      </SectionHeader>
      {description ? (
        <AdvisorSectionDescription style={{ marginTop: 8, marginBottom: 8 }}>
          {description}
        </AdvisorSectionDescription>
      ) : null}

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}
      >
        {advisors.map((advisor) =>
          isConsultants ? (
            <BranchConsultantCardTeamPhoto
              key={advisor.id}
              consultant={advisor}
            />
          ) : (
            <BranchAdvisorCardTeamPhoto key={advisor.id} advisor={advisor} />
          )
        )}
      </Box>
    </Box>
  );
};

export default TeamMembersGrid;
