import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

interface OfficeInfoProps {
  branchName: string;
  address: string;
  address2: string;
  city: string;
  phone: string;
  secondaryOfficePhone?: string;
  postalCode: string;
  province: string;
  officeHours: {
    Sunday: string | undefined;
    Monday: string | undefined;
    Tuesday: string | undefined;
    Wednesday: string | undefined;
    Thursday: string | undefined;
    Friday: string | undefined;
    Saturday: string | undefined;
  };
  website: string;
}

const OfficeInfo: React.FC<{
  props: OfficeInfoProps;
  style?: React.CSSProperties;
}> = ({ props, style }) => {
  return (
    <OfficeInfoContainer style={{ ...style }}>
      <SmallTitle>Office Information</SmallTitle>
      <AddressColumn>
        <MiniTitle>Address</MiniTitle>
        <FriendlyTeamNameText>{props.branchName}</FriendlyTeamNameText>
        <AddressText>{props.address}</AddressText>
        <AddressText>{`${props.city} ${props.province} `}</AddressText>
        <AddressText>{`${props.postalCode}`}</AddressText>
        <MiniTitle>Contact Information</MiniTitle>
        <ContactText>{`Phone: ${props.phone}`}</ContactText>
        {props.secondaryOfficePhone ? (
          <Box ml="55px">
            <ContactText>{`${props.secondaryOfficePhone}`}</ContactText>
          </Box>
        ) : null}

        <ContactText>
          Website:{' '}
          <ContactColumnWebsite
            target="_blank"
            href={`https://www.${props.website}`}
          >
            {props.website}
          </ContactColumnWebsite>
        </ContactText>
      </AddressColumn>
      <ContactColumn>
        <MiniTitle>Office Hours</MiniTitle>
        <HoursWeek>
          <Date>
            <DateText>Sunday</DateText>
            <DateText>{props.officeHours.Sunday}</DateText>
          </Date>
          <Date>
            <DateText>Monday</DateText>
            <DateText>{props.officeHours.Monday}</DateText>
          </Date>
          <Date>
            <DateText>Tuesday</DateText>
            <DateText>{props.officeHours.Tuesday}</DateText>
          </Date>
          <Date>
            <DateText>Wednesday</DateText>
            <DateText>{props.officeHours.Wednesday}</DateText>
          </Date>
          <Date>
            <DateText>Thursday</DateText>
            <DateText>{props.officeHours.Thursday}</DateText>
          </Date>
          <Date>
            <DateText>Friday</DateText>
            <DateText>{props.officeHours.Friday}</DateText>
          </Date>
          <Date>
            <DateText>Saturday</DateText>
            <DateText>{props.officeHours.Saturday}</DateText>
          </Date>
        </HoursWeek>
      </ContactColumn>
    </OfficeInfoContainer>
  );
};

export default OfficeInfo;

const FriendlyTeamNameText = styled.p`
  font-weight: 600;
  font-family: Gill Sans;
  color: black;
`;
const AddressText = styled.p`
  color: #000000;
  font-family: Gill Sans;
  @media screen and (max-width: 767px) {
  }
`;

const OfficeInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  margin-bottom: 40px;
  margin-top: 40px;
  background-color: #ffffff;
  border: 1px solid #d4e7ed;
  box-shadow: 0px 4px 12px rgba(37, 47, 35, 0.15);
  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 20px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    padding: 20px;
  }
`;

/* Text */

const SmallTitle = styled.h3`
  font-family: Poppins;
  font-weight: 200;
  font-size: 32px;
  line-height: 135%;
  width: 90%;
  margin: 0px;
  margin-bottom: 25px;
  @media screen and (max-width: 767px) {
    font-size: 25px;
    line-height: 125%;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    font-size: 26px;
    line-height: 125%;
  }
`;

const MiniTitle = styled.p`
  font-family: Gill Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 165%;
  color: #000000;
  margin-top: 0px;
`;

const ContactText = styled.p`
  color: #000000;
  font-family: Gill Sans;
  @media screen and (max-width: 767px) {
    margin-bottom: 5px;
  }
`;

const DateText = styled.p`
  margin-top: 0px;
  color: #000000;
  font-family: Gill Sans;
`;

const ContactColumnWebsite = styled.a`
  color: #000000;
  word-break: break-word;
  text-decoration: underline;
`;

/* End Text */

const AddressColumn = styled.div`
  width: 47%;
  font-size: 18px;
  @media screen and (max-width: 767px) {
    width: 100%;
    font-size: 16px;
  }
`;

const ContactColumn = styled.div`
  width: 47%;
  font-size: 18px;
  @media screen and (max-width: 767px) {
    width: 100%;
    font-size: 16px;
  }
`;

const Date = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HoursWeek = styled.div`
  display: flex;
  flex-direction: column;
  }
`;
