import React from 'react';
import styled from 'styled-components';
import queryString from 'query-string';

import mapboxAdvisorCollect from 'queries/mapboxAdvisorCollect';
import searchfieldAdvisorCollect from 'queries/searchfieldAdvisorCollect';
import { AdvisorHybrid } from 'models/advisor';
import { CoordinatePair } from 'models/coordinate';
import HaversineFormula from 'utilities/haversineFormula';

import SearchScreen from 'components/Screens/SearchScreen';
import ResultsScreen from 'components/Screens/ResultsScreen';
import AdvisorScreen from 'components/Screens/AdvisorScreen';
import EditTeamMemberScreen from 'components/Screens/EditTeamMemberScreen';
import bannerFlair from 'assets/images/bannerFlair.svg';
import EditBranchScreen from './Screens/EditBranchScreen';
import BranchLandingScreen from './Screens/BranchLandingScreen';
import collectSpecificAdvisor from 'queries/collectSpecificAdvisor';

enum ViewingMode {
  Search = 'Search',
  Details = 'Details',
  EditTeamMember = 'editTeamMember',
  EditBranch = 'editBranch',
  BranchLanding = 'branchLanding'
}

const MainContainer: React.FC = () => {
  const urlVariables = queryString.parse(window.location.search);
  const [allAdvisors, setAllAdvisors] = React.useState<AdvisorHybrid[]>([]);
  const [filteredAdvisors, setFilteredAdvisors] = React.useState<
    AdvisorHybrid[]
  >([]);
  const [selectedAdvisor, setSelectedAdvisor] =
    React.useState<AdvisorHybrid | undefined>(undefined);
  const [userLocation, setUserLocation] =
    React.useState<CoordinatePair | undefined>(undefined);
  const [viewingMode, setViewingMode] =
    React.useState<undefined | ViewingMode>(undefined);
  const [loading, setLoading] = React.useState(false);
  const [mapboxSearchType, setMapboxSearchType] =
    React.useState<undefined | string>(undefined);
  const [searchType, setSearchType] = React.useState('location');

  const [isPWAdvisorOnly, setIsPWAdvisorOnly] = React.useState(false);
  const [initialSearchPerformed, setInitialSearchPerformed] =
    React.useState(false);

  const radius = 100;

  const collectAddressBasedAdvisors = async () => {
    setLoading(true);
    const contentfulObject = await mapboxAdvisorCollect();
    setAllAdvisors(contentfulObject);
    setLoading(false);
  };

  const collectSearchFieldAdvisors = async (searchString: string) => {
    setLoading(true);
    const contentfulObject = await searchfieldAdvisorCollect(searchString);
    setAllAdvisors(contentfulObject);
    setLoading(false);
  };

  React.useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === '/ipc-private-wealth') {
      setIsPWAdvisorOnly(true);
    }
  }, []);

  const with_pw_filter = (advisors: AdvisorHybrid[]) =>
    isPWAdvisorOnly
      ? advisors.filter(
          (advisor) => advisor.advisorFields.privateWealthAdvisor === true
        )
      : advisors;

  React.useEffect(() => {
    if (
      allAdvisors.length > 0 &&
      userLocation?.latitude &&
      searchType === 'location'
    ) {
      const nearbyAdvisors: AdvisorHybrid[] = [];
      allAdvisors.forEach((advisorTeam) => {
        if (
          (advisorTeam.advisorFields.primaryOffice &&
            HaversineFormula(
              userLocation.latitude,
              userLocation.longitude,
              advisorTeam.advisorFields.primaryOffice.lat,
              advisorTeam.advisorFields.primaryOffice.lon
            ) <= radius) ||
          HaversineFormula(
            userLocation.latitude,
            userLocation.longitude,
            advisorTeam.teamFields.lat,
            advisorTeam.teamFields.lon
          ) <= radius
        ) {
          if (
            advisorTeam.advisorFields.primaryOffice &&
            HaversineFormula(
              userLocation.latitude,
              userLocation.longitude,
              advisorTeam.advisorFields.primaryOffice.lat,
              advisorTeam.advisorFields.primaryOffice.lon
            ) <= radius
          ) {
            nearbyAdvisors.push(advisorTeam);
          }
          if (
            !advisorTeam.advisorFields.primaryOffice &&
            HaversineFormula(
              userLocation.latitude,
              userLocation.longitude,
              advisorTeam.teamFields.lat,
              advisorTeam.teamFields.lon
            ) <= radius
          ) {
            nearbyAdvisors.push(advisorTeam);
          }
        }
      });
      setFilteredAdvisors(with_pw_filter(nearbyAdvisors));
    }
    if (allAdvisors.length > 0 && searchType === 'advisorName') {
      setFilteredAdvisors(with_pw_filter(allAdvisors));
    }
    if (allAdvisors.length === 0 && !userLocation?.latitude) {
      setFilteredAdvisors([]);
    }
  }, [allAdvisors, userLocation, isPWAdvisorOnly]);

  const handleAdvisorSelect = (advisorTeam: AdvisorHybrid) => {
    setSelectedAdvisor(advisorTeam);
  };

  const goBack = () => {
    setSelectedAdvisor(undefined);

    if (urlVariables.advisorLocatorSlug) {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete('advisorLocatorSlug');

      window.location.href = newUrl.toString();
    }
  };

  React.useEffect(() => {
    //http://localhost:3000/?advisorTeamSlug=harbour-financial-group-stephanie-power&teamMemberSlug=stephanie-power-slug
    if (urlVariables.teamMemberSlug && urlVariables.advisorTeamSlug) {
      setViewingMode(ViewingMode.EditTeamMember);
    }
    if (selectedAdvisor) {
      setViewingMode(ViewingMode.Details);
    } else if (typeof urlVariables.advisorLocatorSlug === 'string') {
      setLoading(true);
      collectSpecificAdvisor(urlVariables.advisorLocatorSlug).then(
        (advisor) => {
          setSelectedAdvisor(advisor);
          setViewingMode(ViewingMode.Details);
          setLoading(false);
        }
      );
      return;
    }
    if (!urlVariables.teamMemberSlug && !selectedAdvisor) {
      setViewingMode(ViewingMode.Search);
    }
    if (urlVariables.branchSlug) {
      setViewingMode(ViewingMode.EditBranch);
    }
    if (urlVariables.branchLandingSlug && !urlVariables.advisorLocatorSlug) {
      setViewingMode(ViewingMode.BranchLanding);
    }
  }, [selectedAdvisor, urlVariables]);

  const isEditScreen = React.useMemo(
    () =>
      [ViewingMode.EditTeamMember, ViewingMode.BranchLanding].some(
        (mode) => mode === viewingMode
      ),
    [viewingMode]
  );

  return (
    <DesktopContainer>
      {isEditScreen ? null : <Header />}
      {viewingMode === ViewingMode.Search ? (
        <Scroller>
          <SearchScreen
            setUserLocation={setUserLocation}
            collectSearchFieldAdvisors={collectSearchFieldAdvisors}
            collectAddressBasedAdvisors={collectAddressBasedAdvisors}
            allAdvisors={allAdvisors}
            loading={loading}
            setMapboxSearchType={setMapboxSearchType}
            setSearchType={setSearchType}
            searchType={searchType}
            setInitialSearchPerformed={setInitialSearchPerformed}
          />
          <BannerFlair />
          <ResultsScreen
            filteredAdvisors={filteredAdvisors}
            userLocation={userLocation}
            handleAdvisorSelect={handleAdvisorSelect}
            setLoading={setLoading}
            mapboxSearchType={mapboxSearchType}
            initialSearchPerformed={initialSearchPerformed}
          />
        </Scroller>
      ) : null}
      {viewingMode === ViewingMode.Details && selectedAdvisor ? (
        <AdvisorScreen goBack={goBack} selectedAdvisor={selectedAdvisor} />
      ) : null}

      {viewingMode === ViewingMode.EditTeamMember ? (
        <EditTeamMemberScreen />
      ) : null}

      {viewingMode === ViewingMode.EditBranch ? <EditBranchScreen /> : null}

      {viewingMode === ViewingMode.BranchLanding ? (
        <BranchLandingScreen />
      ) : null}
    </DesktopContainer>
  );
};

const DesktopContainer = styled.main`
  width: 100%;
  font-family: Gill Sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  position: relative;
`;

const Header = styled.div`
  padding-top: 150px;
  background-color: #006331;
  @media screen and (max-width: 980px) {
    padding-top: 90px;
  }
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
`;

const Scroller = styled.div`
  padding-top: 150px;
  @media screen and (max-width: 980px) {
    padding-top: 90px;
  }
  height: 100vh;
  overflow-x: scroll;
`;

const BannerFlair = styled.div`
  height: 68px;
  width: 100%;
  background-image: url(${bannerFlair});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export default MainContainer;
