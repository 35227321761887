import { Box } from '@mui/material';
import {
  BranchInfoText,
  Divider,
  ExpertisePill,
  SectionHeader
} from '../StyledComponents';
import { Branch } from 'models/branch';

const BranchExpertiseSlice = ({
  expertise,
  branch
}: {
  expertise: string[];
  branch: Branch;
}) => {
  return (
    <>
      <SectionHeader
        style={{
          marginBottom: 15,
          paddingBottom: 0,
          fontSize: '44px',
          fontWeight: 275
        }}
      >
        Our Expertise
      </SectionHeader>
      <Divider
        style={{
          backgroundColor: 'rgba(131, 175, 92, 1)',
          margin: '0 0 10px'
        }}
      />
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {expertise.map((expertise) => (
          <ExpertisePill key={expertise}>{expertise}</ExpertisePill>
        ))}
      </Box>
      <BranchInfoText>
        {branch.branchDetails.branchIntro ??
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, et mattis vivamus bibendum et libero mattis. Gravida lacus morbi aliquam dui. Duis nunc, massa varius justo. Eu pharetra sit risus nibh eleifend ultrices egestas. Nulla sollicitudin nulla justo id tristique a etiam potenti ullamcorper.'}
      </BranchInfoText>
    </>
  );
};

export default BranchExpertiseSlice;
