import styled from 'styled-components';
import PWMSWheel from '../../../assets/images/pwms-wheel.png';

const PWMSSlice = () => (
  <Container>
    <ContainerInner>
      <div style={{ width: '45%', padding: '50px' }}>
        <h2>The Personal Wealth Management Strategy</h2>
        <p>
          Our team adopts a disciplined process using The Personal Wealth
          Management StrategyTM. This system allows us to examine all aspects of
          your financial well-being – from evaluating your current state to
          understanding your dreams and aspirations – to build the plan that's
          right for you.
        </p>
      </div>
      <div>
        <Image
          src={PWMSWheel}
          alt="Personal wealth management strategy wheel"
        />
      </div>
    </ContainerInner>
  </Container>
);

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 10px;
  h2 {
    font-size: 44px;
    font-weight: 300;
    font-family: Poppins;
    margin-top: 0;
  }
  p {
    opacity: 80%;
  }
`;

const ContainerInner = styled.div`
  display: flex;
  max-width: 1200px;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    flex-direction: column;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 700px;
`;

export default PWMSSlice;
