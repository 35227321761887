import { AdvisorHybrid } from 'models/advisor';
import Button from '../Button';
import { ReactGA4Event } from 'utilities/reactGA4Event';

const StartConversationBtn = ({
  selectedAdvisor,
  setActive
}: {
  selectedAdvisor: AdvisorHybrid;
  setActive: (value: React.SetStateAction<boolean>) => void;
}) => {
  return (
    <Button
      type="button"
      handleClick={() => {
        if (selectedAdvisor.advisorFields.bookAnAppointment) {
          ReactGA4Event('event', 'internal_click', {
            link_category: 'find_an_advisor',
            advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
            link_id: 'openAdvisorPopup',
            link_text: 'Book an Appointment',
            link_url: 'openAdvisorPopup'
          });
          window.open(
            `${selectedAdvisor.advisorFields.bookAnAppointment}`,
            '_blank'
          );
        } else {
          ReactGA4Event('event', 'internal_click', {
            link_category: 'find_an_advisor',
            advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
            link_id: 'openAdvisorPopup',
            link_text: 'Start a conversation',
            link_url: 'openAdvisorPopup'
          });
          setActive(true);
        }
      }}
      theme="CTA"
      style={{
        color: 'rgb(255, 255, 255)',
        fontFamily: 'Gill Sans',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '26.4px',
        letterSpacing: '0.5px'
      }}
      uniqueId="openAdvisorPopup"
      text={
        selectedAdvisor.advisorFields.bookAnAppointment
          ? 'Book an Appointment'
          : 'Start a conversation'
      }
    />
  );
};

export default StartConversationBtn;
