import { Form, Formik } from 'formik';
import { BranchFormValues } from 'models/branch';
import React from 'react';
import {
  Divider,
  FormSection,
  FormWrapper,
  LoadingContainer,
  SectionHeader
} from '../StyledComponents';
import {
  FormikRadioGroup,
  FormikTextArea,
  FormikTextField
} from 'components/Form';
import { Box, Card, Typography } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import Button from 'components/General/Button';
import { enqueueSnackbar } from 'notistack';
import postBranchDetails from 'queries/postBranchDetail';
import { getMappedBranchDetailsForPost } from 'utilities/branch';
import _ from 'lodash';
import formikStyles from 'styles/Formik.module.css';
import { FormikSelectField } from 'components/Form/FormikSelectField';
import { provinces } from 'utilities/CheckboxLists';
import { updateBranchSchema } from 'constants/schemas';

type BranchDetailsExtended = BranchFormValues & {
  expertise: string;
  advisorTeams: string;
};

interface BranchEditFormProps {
  initialValues: BranchDetailsExtended;
  activeStep: number;
  totalSteps: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  locked: boolean;
  setLocked: React.Dispatch<React.SetStateAction<boolean>>;
}

const BranchEditForm: React.FC<BranchEditFormProps> = ({
  initialValues,
  activeStep,
  totalSteps,
  setActiveStep,
  locked,
  setLocked
}) => {
  const [loading, setLoading] = React.useState(false);
  const last_step = activeStep === totalSteps;

  const handleNext = () => {
    if (last_step) {
      setLocked(true);
      enqueueSnackbar('Your review is now completed', {
        variant: 'success'
      });
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const submitChanges = async (values: BranchDetailsExtended) => {
    if (!values.id) return;

    // if no changes, skip submitting and go to next step
    if (_.isEqual(values, initialValues)) {
      handleNext();
      return;
    }

    setLoading(true);
    const branch_edits_finalized = getMappedBranchDetailsForPost(values);

    try {
      await postBranchDetails({
        id: values.id,
        branchEdits: branch_edits_finalized
      });
      enqueueSnackbar('Updates have been submitted successfully', {
        variant: 'success'
      });
      setLoading(false);
      handleNext();
    } catch (err) {
      console.log('error: ', err);
      enqueueSnackbar(
        'Something went wrong, please screenshot your webpage and contact your administrator',
        {
          variant: 'error'
        }
      );
      setLoading(false);
    }
  };

  const phtotoTemplates = [
    { id: 'teamPhotoTemplate', label: 'Team Photo Template' },
    { id: 'noTeamPhoto', label: 'No Team Photo' }
  ];

  const officeHours = React.useMemo(() => {
    const officeHoursObj = JSON.parse(initialValues.officeHours ?? '{}');
    return Object.keys(officeHoursObj).map((key) => [key, officeHoursObj[key]]);
  }, [initialValues.officeHours]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitChanges}
      validationSchema={updateBranchSchema}
      enableReinitialize
    >
      {({ isValid }) => (
        <Box sx={{ width: '100%' }}>
          <Form>
            <SectionHeader>Your Branch</SectionHeader>
            <FormWrapper>
              <FormSection>
                <FormikTextField
                  label="Branch Name"
                  name="branchName"
                  disabled={locked}
                />
                <FormikTextField
                  label="Branch Website Link"
                  name="branchSlug"
                  disabled
                />
                <FormikTextField
                  label="Address"
                  name="branchLocation.address"
                  disabled={locked}
                />
                <FormikTextField
                  label="Address 2"
                  name="branchLocation.address2"
                  disabled={locked}
                />
                <FormikTextField
                  label="City"
                  name="branchLocation.city"
                  disabled={locked}
                />
                <FormikSelectField
                  label="Province"
                  name="branchLocation.province"
                  options={provinces}
                  disabled={locked}
                />
                <FormikTextField
                  label="Postal Code"
                  name="branchLocation.postalCode"
                  disabled={locked}
                />
                <FormikTextField
                  label="Phone"
                  name="branchLocation.officePhone"
                  disabled={locked}
                />
                <FormikTextField
                  label="Secondary Phone"
                  name="branchLocation.secondaryOfficePhone"
                  disabled={locked}
                />
                <Box style={{ gridColumn: 'span 2' }}>
                  <FormikTextField
                    label="Advisor Teams"
                    name="advisorTeams"
                    disabled
                  />
                </Box>
                <Box style={{ gridColumn: 'span 2' }}>
                  <FormikTextField
                    label="Branch Expertise"
                    name="expertise"
                    placeholder="Expertise 1, Expertise 2, Expertise 3, Expertise 4"
                    description="This is an aggregated list pulled from each advisor’s expertise list"
                    disabled
                  />
                </Box>
                <Box style={{ gridColumn: 'span 2' }}>
                  <Typography className={formikStyles.label}>
                    Office Hours
                  </Typography>
                  <Card
                    sx={{
                      boxShadow: 'none',
                      backgroundColor: '#f5f8f9',
                      p: 2,
                      minHeight: '100px',
                      cursor: 'not-allowed'
                    }}
                  >
                    {officeHours.map(([key, value]) => (
                      <Typography key={key}>
                        {key}: {value}
                      </Typography>
                    ))}
                  </Card>
                </Box>
                <Box style={{ gridColumn: 'span 2' }}>
                  <FormikTextArea
                    label="Branch Intro"
                    name="branchIntro"
                    placeholder="Branch intro here..."
                    style={{ height: '100px' }}
                    disabled={locked}
                  />
                </Box>
                <FormikRadioGroup
                  name="branchPhotoTemplate"
                  label="Template"
                  options={phtotoTemplates.map((item) => ({
                    value: item.id,
                    label: item.label
                  }))}
                  disabled={locked}
                />
                <Box style={{ gridColumn: 'span 2' }}>
                  <Divider />
                  <FormikTextArea
                    label="Your Branch Comments or Change Requests"
                    name="branchComments"
                    placeholder="Your comments here..."
                    style={{ height: '100px' }}
                    disabled={locked}
                  />
                </Box>
                <Box
                  style={{
                    gridColumn: 'span 2',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box>
                    <Typography>
                      {activeStep} of {totalSteps}
                    </Typography>
                  </Box>
                  {loading ? (
                    <LoadingContainer>
                      <ClipLoader
                        color={'#FFFFFF'}
                        loading={loading}
                        size={25}
                      />
                    </LoadingContainer>
                  ) : (
                    <Box>
                      <Button
                        disabled={last_step && locked}
                        type="submit"
                        text={last_step ? 'Submit & Approve' : 'Next'}
                        theme={last_step ? undefined : 'white'}
                        style={
                          last_step
                            ? {
                                color: '#FFFFFF'
                              }
                            : {
                                border: 'none',
                                color: '#000',
                                backgroundColor: '#DBE3E0'
                              }
                        }
                      />
                      {!isValid && (
                        <Typography
                          sx={{ fontSize: '10px', fontStyle: 'italic', mt: 1 }}
                        >
                          *Please check errors in the form
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </FormSection>
            </FormWrapper>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default BranchEditForm;
