import { createClient } from 'contentful';
import { ContentfulResponse } from 'models/contentfulResponse';
import { constructAdvisorHybrid } from './helpers/constructAdvisorHybrid';

const searchfieldAdvisorCollect = async (advisorName: string) => {
  const contentfulClient = createClient({
    accessToken: `${process.env.REACT_APP_CONTENTFUL_SECRET_TOKEN}`,
    space: `${process.env.REACT_APP_CONTENTFUL_SPACE_ID}`,
    environment: `${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}`
  });
  const primaryOfficeArray: any[] = [];
  const teamMemberIDS = await contentfulClient
    .getEntries({
      'fields.name[match]': advisorName,
      content_type: 'teamMember',
      limit: 1000,
      select: ['fields.name', 'fields.primaryOffice']
    })

    .then((entries: any) => {
      const allAdvisors: string[] = [];
      entries.items.forEach((advisorName: ContentfulResponse) => {
        if (advisorName.fields.primaryOffice) {
          primaryOfficeArray.push(advisorName.fields.primaryOffice);
        }
        allAdvisors.push(advisorName.sys.id);
      });

      return allAdvisors;
    });

  return constructAdvisorHybrid(teamMemberIDS, primaryOfficeArray);
};

export default searchfieldAdvisorCollect;
