import React from 'react';
import styled from 'styled-components';
import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  DialogContent,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import image from 'assets/images/placeholder.svg';
import { TeamMemberDetails } from 'models/branch';

const AdvisorBioModal: React.FC<{
  advisor: TeamMemberDetails;
  open: boolean;
  onClose: () => void;
}> = ({ advisor, open, onClose }) => (
  <Dialog open={open} onClose={onClose} maxWidth="md">
    <DialogTitle sx={{ display: 'flex', alignItems: 'center', p: 0 }}>
      <Box sx={{ flex: 1 }} />
      <Box>
        <IconButton onClick={onClose}>
          <Close fontSize="large" />
        </IconButton>
      </Box>
    </DialogTitle>
    <DialogContent sx={{ mx: 2, mb: 2 }}>
      <ContentContainer>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Stack spacing={1} sx={{ textAlign: 'center' }}>
              <img src={advisor.photo || image} alt="Advisor" />
              <Typography variant="h5">{advisor.name}</Typography>
              <Typography variant="subtitle1">{advisor.title}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1" sx={{ lineHeight: '33.3px' }}>
              {advisor.description}
            </Typography>
          </Grid>
        </Grid>
      </ContentContainer>
    </DialogContent>
  </Dialog>
);

const ContentContainer = styled.div`
  h5 {
    font-family: Poppins;
    font-weight: 700;
    font-size: '28px';
  }
  body1 {
    font-family: Poppins;
    font-weight: 400;
    font-size: '18px';
  }
  subtitle1 {
    font-family: Gill Sans;
    font-weight: 400;
    font-size: '15px';
  }
`;

export default AdvisorBioModal;
