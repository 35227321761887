import React from 'react';
import styled from 'styled-components';
import { Box, Grid, Typography } from '@mui/material';
import { ContentfulAsset } from 'models/contentfulResponse';

type CustomContentSliceProps = {
  title: string;
  subtitle?: string;
  bulletPoints?: { title: string; description: string }[];
  image?: ContentfulAsset;
};

const CustomContentSlice: React.FC<CustomContentSliceProps> = ({
  title,
  subtitle,
  bulletPoints,
  image
}) => (
  <Container>
    <ContainerInner>
      <Box sx={{ mb: '40px' }}>
        <Grid container spacing={4}>
          <Grid container item spacing={2} xs={8}>
            <Grid item xs={12} sx={{ alignContent: 'center' }}>
              <Typography variant="h2">{title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">{subtitle}</Typography>
            </Grid>
            {bulletPoints?.map((bulletPoint, index) => (
              <Grid item xs={6} key={index}>
                <Typography variant="h6" sx={{ pb: 1 }}>
                  {bulletPoint.title}
                </Typography>
                <Typography variant="body1">
                  {bulletPoint.description}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={4}>
            <img
              src={image ? `https:${image.fields.file.url}` : ''}
              alt={image ? image.fields.description ?? image.fields.title : ''}
              style={{
                maxWidth: '492px',
                maxHeight: '512px',
                width: 'auto',
                height: 'auto'
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </ContainerInner>
  </Container>
);

const Container = styled.div`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 10px;
  h2 {
    font-size: 32px;
    font-weight: 300;
    font-family: Poppins;
    margin-top: 0;
  }
  h5 {
    font-size: 24px;
    font-weight: 400;
    font-family: Gill Sans;
    margin-top: 0;
  }
  h6 {
    font-size: 18px;
    font-weight: 600;
    font-family: Gill Sans;
    margin-top: 0;
    line-height: 29.7px;
  }
  body1 {
    font-size: 15px;
    font-weight: 400;
    font-family: Gill Sans;
    margin-top: 0;
    line-height: 24.75px;
  }
  p {
    opacity: 80%;
  }
`;

const ContainerInner = styled.div`
  display: flex;
  max-width: 1200px;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export default CustomContentSlice;
