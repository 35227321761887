export function youTubePlaylistStyles() {
  const loaderContainerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh'
  };
  const imageBtnStyle: React.CSSProperties = {
    border: 'none',
    background: 'none',
    margin: 0,
    padding: 0,
    display: 'block',
    width: '100%'
  };
  const hide: React.CSSProperties = {
    display: 'none'
  };
  const videoContainerStyle: React.CSSProperties = {
    margin: '10px',
    display: 'flex',
    flex: 1
  };

  const videoContainerStyleFeatured: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px'
  };

  const videoImageStyle: React.CSSProperties = {
    width: '40%',
    cursor: 'pointer'
  };
  const videoImageStyleFeatured: React.CSSProperties = {
    maxWidth: '100%'
  };

  const videoCaptionStyle: React.CSSProperties = {
    textAlign: 'left',
    fontWeight: 400,
    fontFamily: 'Poppins',
    marginLeft: '0.5rem',
    fontSize: '18px'
  };

  const videoCaptionStyleFeatured: React.CSSProperties = {
    textAlign: 'left',
    fontWeight: 300,
    fontSize: '28px',
    fontFamily: 'Poppins',
    lineHeight: '38px',
    margin: '15px 0'
  };

  const modalContainerStyle: React.CSSProperties = {
    outline: 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.73)',
    WebkitBackdropFilter: 'blur(5px)',
    backdropFilter: 'blur(5px)'
  };
  const modalSlideNumberStyle: React.CSSProperties = {
    zIndex: 1,
    position: 'absolute',
    left: 0,
    padding: '13px',
    backgroundColor: 'rgba(35, 35, 35, 0.73)',
    color: '#fff',
    fontSize: '0.93rem',
    transition: 'opacity 1s ease-in-out',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  };
  const modalToolbarStyle: React.CSSProperties = {
    zIndex: 1,
    display: 'flex',
    position: 'absolute',
    right: 0,
    alignItems: 'center',
    backgroundColor: 'rgba(35, 35, 35, 0.73)',
    transition: 'opacity 1s ease-in-out',
    cursor: 'pointer'
  };
  const modalToolbarBtnStyle: React.CSSProperties = {
    margin: 0,
    border: 'none',
    background: 'none',
    padding: '13px',
    color: '#fff',
    cursor: 'pointer'
  };
  const modalSlideShowSectionStyle: React.CSSProperties = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: 'inherit',
    height: 'inherit'
  };
  const modalIFrameStyle: React.CSSProperties = {
    margin: 'auto',
    width: '80%',
    aspectRatio: '16/9',
    border: 'none'
  };
  const modalSlideBtnStyle: React.CSSProperties = {
    position: 'absolute',
    border: 'none',
    marginInline: '7px',
    padding: '10px 15px',
    backgroundColor: 'rgba(35, 35, 35, 0.73)',
    color: '#fff',
    transition: 'opacity 1s ease-in-out',
    cursor: 'pointer',
    userSelect: 'none',
    WebkitUserSelect: 'none'
  };
  return {
    loaderContainerStyle,
    imageBtnStyle,
    videoContainerStyle,
    videoImageStyle,
    videoCaptionStyle,
    modalContainerStyle,
    modalSlideNumberStyle,
    modalToolbarStyle,
    modalToolbarBtnStyle,
    modalSlideShowSectionStyle,
    modalIFrameStyle,
    modalSlideBtnStyle,
    hide,
    videoContainerStyleFeatured,
    videoImageStyleFeatured,
    videoCaptionStyleFeatured
  };
}
