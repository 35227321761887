import styled from 'styled-components';

const PodcastsSlice: React.FC<{ src: string }> = ({ src }) => (
  <Container>
    <ContainerInner>
      <div style={{ width: '80%', textAlign: 'center', marginBottom: '40px' }}>
        <h2>Podcasts</h2>
        <p>
          As your wealth grows, there will come a point when you begin to expect
          more from your investments. IPC Private Wealth is a disciplined,
          discretionary, highly personalized service offering.
        </p>
      </div>
      <iframe
        style={{ borderRadius: '12px', borderColor: 'transparent' }}
        src={src}
        width="800"
        height="352"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </ContainerInner>
  </Container>
);

const Container = styled.div`
  background-color: #f5f8f9;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 10px;
  h2 {
    font-size: 44px;
    font-weight: 300;
    font-family: Poppins;
    margin-top: 0;
    text-align: center;
  }
  p {
    opacity: 80%;
  }
`;

const ContainerInner = styled.div`
  display: flex;
  max-width: 800px;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export default PodcastsSlice;
