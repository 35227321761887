import styled from 'styled-components';

export const LoadingContainer = styled.div`
  width: 148px;
  height: 54px;
  background-color: #016432;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 55%;
  min-width: 800px;
  box-sizing: border-box;
  padding: 50 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 auto;
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  align-items: center;
  justify-content: center;
  font-size: 44px;
  margin-top: 70px;
  text-align: center;
  line-height: normal;
`;

export const Subtext = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-top: 50px;
`;

export const SectionHeader = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  align-items: center;
  justify-content: flex-start;
  font-size: 32px;
  margin-top: 50px;
  padding-bottom: 30px;
`;

export const SectionHeaderLarge = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  font-family: Poppins;
  font-style: normal;
  font-weight: 275;
  align-items: center;
  justify-content: center;
  font-size: 52px;
  margin-top: 50px;
`;

export const SectionHeaderTextSmall = styled.div`
  text-align: center;
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
`;

export const Divider = styled.hr`
  background-color: #f5f8f9;
  margin: 20px 0px;
  border: unset;
  height: 3px;
  width: 100%;
`;

export const FormWrapper = styled.div`
  padding-bottom: 70px;
`;

export const FormSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 70px);
  grid-gap: 30px;
  margin-bottom: 5px;
`;

export const PhotoContainer = styled.div`
  display: flex;
  grid-row: 1 / span 3;
`;

export const Photo = styled.img`
  max-width: 230px;
  height: auto;
`;

export const Tooltip = styled.p``;

export const PhotoDetails = styled.div`
  box-sizing: border-box;
  padding: 15px;
  padding-top: 0px;
`;

export const ExpertisePill = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-wrap: nowrap;
  font-size: 18px;
  font-weight: 400;
  background-color: rgba(240, 246, 243, 1);
  color: rgba(0, 70, 45, 1);
  border-radius: 25px;
  padding: 8px 16px 8px 16px;
  margin: 8px 8px 8px 0px;
`;

export const BranchInfoText = styled.div`
  font-family: Gill Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 100px;
`;

export const OurDifferenceGreenBackground = styled.div`
  width: 30%;
  height: 100%;
  background: linear-gradient(24.64deg, #232926 10.44%, #006331 93.74%);
  position: absolute;
  top: 0;
  left: 0;
  transform: skewX(-10deg) scaleX(1.6);
`;

export const OurDifferenceDescriptionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 88px;
`;

export const OurDifferenceDescription = styled.div`
  font-family: Poppins;
  font-size: 2vw;
  font-weight: 400;
  line-height: 40px;
  margin-top: 20px;
  color: rgba(249, 249, 251, 1);
  padding: 32px;
`;

export const AdvisorSectionDescription = styled.div`
  font-family: Gill Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
`;

export const BranchLandingPhotoContainer = styled.div`
  width: 100%;
  height: auto;
  max-height: 939px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: flex-end;
  img {
    align-self: flex-end;
    width: 80%;
    object-fit: contain;
  }
`;

export const TeamPhotoContainer = styled.div`
  width: auto;
  height: auto;
  max-height: 645px;
  overflow: hidden;
  display: flex;
  margin-bottom: 70px;
  margin-top: 50px;
  img {
    width: 100%;
    object-fit: cover;
  }
`;
