import { Box } from '@mui/material';
import { Subtext, Title } from '../StyledComponents';
import { Branch } from 'models/branch';
import { provinceToFull } from 'constants/provinceToFull';
import bannerBackground from '../../../assets/images/no-team-photo-bg.png';

const BranchNoTeamPhotoHeader = ({ branch }: { branch: Branch }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundImage: `url(${bannerBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <Title
        style={{
          fontSize: '60px',
          fontWeight: 275,
          color: '#FFFFFF',
          marginTop: 150
        }}
      >{`${branch.branchDetails.branchName ?? ''}`}</Title>
      <Subtext
        style={{
          fontSize: '24px',
          color: '#FFFFFF',
          marginTop: 0,
          marginBottom: 150
        }}
      >
        {provinceToFull[branch.branchDetails.branchLocation?.province ?? '']}
      </Subtext>
    </Box>
  );
};

export default BranchNoTeamPhotoHeader;
