import React from 'react';
import styled from 'styled-components';

const ExpertiseCard: React.FC<{
  text: string;
}> = ({ text }) => {
  return (
    <Expertise>
      <Text>{typeof text === 'string' ? text : null}</Text>
    </Expertise>
  );
};

const Expertise = styled.div`
  background: rgba(245, 248, 249, 0.1);
  padding: 12px 16px;
  border-radius: 41px;
  width: auto;
  margin-right: 10px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 10px;
    font-size: 18px;
  }
  margin-bottom: 10px;
`;

const Text = styled.div`
  font-family: Gill Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: rgb(255, 255, 255);
  margin: 0px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export default ExpertiseCard;
