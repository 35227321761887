import { Box } from '@mui/material';
import {
  BranchLandingPhotoContainer,
  Container,
  OurDifferenceDescription,
  OurDifferenceDescriptionContainer,
  OurDifferenceGreenBackground,
  SectionHeaderLarge,
  SectionHeaderTextSmall
} from 'components/General/StyledComponents';
import { Branch } from 'models/branch';

import React from 'react';
import backgroundImage from './../../../assets/images/backgroundStockPhoto.jpg';
import OfficeInfo from 'components/General/OfficeInfo';
import {
  BranchPageSettingsData,
  PageSliceOptions
} from 'queries/collectDudaSettings';
import BranchPlaceholderPageSection from './BranchPlaceholderPageSection';
import BranchExpertiseSlice from './BranchExpertiseSlice';
import BranchNoTeamPhotoHeader from './BranchNoTeamPhotoHeader';
import BranchTeamPhotoHeader from './BranchTeamPhotoHeader';
import TeamMembersGrid from './TeamMembersGrid';

interface BranchTemplateProps {
  branch: Branch;
  expertise: string[];
  dudaData?: BranchPageSettingsData;
  loading: boolean;
  errorBranch: string;
  isTeamPhotoTemplate?: boolean;
}

const BranchTemplate: React.FC<BranchTemplateProps> = ({
  branch,
  expertise,
  dudaData,
  loading,
  errorBranch,
  isTeamPhotoTemplate
}) => {
  const error = (
    <Container>
      <Box sx={{ my: 3 }}>Something went wrong: {errorBranch}</Box>
    </Container>
  );

  const loadingScreen = <Box py="100px" />;

  const iirocAdvisors = React.useMemo(() => {
    return branch.teamMembers.filter(
      (teamMember) => teamMember.typeOfAdvisor === 'IIROC'
    );
  }, [branch.teamMembers]);

  const mfdaAdvisors = React.useMemo(() => {
    return branch.teamMembers.filter(
      (teamMember) => teamMember.typeOfAdvisor === 'MFDA'
    );
  }, [branch.teamMembers]);

  if (loading) return loadingScreen;
  if (errorBranch) return error;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {isTeamPhotoTemplate ? (
        <BranchTeamPhotoHeader branch={branch} />
      ) : (
        <BranchNoTeamPhotoHeader branch={branch} />
      )}
      {isTeamPhotoTemplate ? (
        <Container style={{ minWidth: 'none', width: '70%' }}>
          <BranchExpertiseSlice expertise={expertise} branch={branch} />
        </Container>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(245, 248, 249, 1)'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '100px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '53px',
              height: '4px',
              backgroundColor: 'rgba(131, 175, 92, 1)',
              marginRight: 2
            }}
          />
          <SectionHeaderTextSmall>OUR TEAM</SectionHeaderTextSmall>
        </Box>
        <SectionHeaderLarge>Meet Our Advisors</SectionHeaderLarge>
        {iirocAdvisors.length > 0 ? (
          <TeamMembersGrid
            advisors={iirocAdvisors}
            title={<span>IPC Securities Corporation</span>}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, et mattis vivamus bibendum et libero mattis."
          />
        ) : null}
        {mfdaAdvisors.length > 0 ? (
          <TeamMembersGrid
            advisors={mfdaAdvisors}
            title={
              <span>
                IPC Investment Corporation<sup>2</sup>
              </span>
            }
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, et mattis vivamus bibendum et libero mattis."
          />
        ) : null}
        {branch.consultants.length > 0 ? (
          <TeamMembersGrid
            advisors={branch.consultants}
            title="Our Consultants"
            isConsultants={true}
          />
        ) : null}
        {isTeamPhotoTemplate ? null : (
          <Container style={{ width: '70%' }}>
            <BranchExpertiseSlice expertise={expertise} branch={branch} />
          </Container>
        )}
        <BranchLandingPhotoContainer style={{ marginTop: '121px' }}>
          <img src={backgroundImage} alt="Advisor" />
          <OurDifferenceGreenBackground />
          <OurDifferenceDescriptionContainer>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '50px',
                paddingLeft: '32px'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '53px',
                  height: '4px',
                  backgroundColor: 'rgba(131, 175, 92, 1)',
                  marginRight: 2
                }}
              />
              <SectionHeaderTextSmall style={{ color: 'rgb(255, 255, 255)' }}>
                OUR DIFFERENCE
              </SectionHeaderTextSmall>
            </Box>
            <OurDifferenceDescription>
              Lorem ipsum dolor set amit consectetur adalipscing massa varius
              justorisus nibh eleifend ultrices egestas. Nulla sollicitudin
              nulla justo id tristique a etiam potenti ullamcorper.
            </OurDifferenceDescription>
          </OurDifferenceDescriptionContainer>
        </BranchLandingPhotoContainer>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#FFFFFF'
          }}
        >
          <OfficeInfo
            props={{
              branchName: branch.branchDetails.branchName ?? '',
              address: branch.branchDetails.branchLocation?.address ?? '',
              address2: branch.branchDetails.branchLocation?.address2 ?? '',
              city: branch.branchDetails.branchLocation?.city ?? '',
              province: branch.branchDetails.branchLocation?.province ?? '',
              postalCode: branch.branchDetails.branchLocation?.postalCode ?? '',
              phone: branch.branchDetails.branchLocation?.officePhone ?? '',
              secondaryOfficePhone:
                branch.branchDetails.branchLocation?.secondaryOfficePhone,
              website: branch.branchDetails.branchSlug ?? '',
              officeHours: {
                Sunday: branch.branchDetails.officeHours
                  ? JSON.parse(branch.branchDetails.officeHours)['Sunday']
                  : '',
                Monday: branch.branchDetails.officeHours
                  ? JSON.parse(branch.branchDetails.officeHours)['Monday']
                  : '',
                Tuesday: branch.branchDetails.officeHours
                  ? JSON.parse(branch.branchDetails.officeHours)['Tuesday']
                  : '',
                Wednesday: branch.branchDetails.officeHours
                  ? JSON.parse(branch.branchDetails.officeHours)['Wednesday']
                  : '',
                Thursday: branch.branchDetails.officeHours
                  ? JSON.parse(branch.branchDetails.officeHours)['Thursday']
                  : '',
                Friday: branch.branchDetails.officeHours
                  ? JSON.parse(branch.branchDetails.officeHours)['Friday']
                  : '',
                Saturday: branch.branchDetails.officeHours
                  ? JSON.parse(branch.branchDetails.officeHours)['Saturday']
                  : ''
              }
            }}
            style={{ marginTop: 150, marginBottom: 250 }}
          />
        </Box>
      </Box>
      {dudaData ? (
        <>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {dudaData['Custom Content'][0] === PageSliceOptions.On ? (
              <BranchPlaceholderPageSection sectionName="customContent" />
            ) : null}
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {dudaData['Latest Videos'][0] === PageSliceOptions.On ? (
              <BranchPlaceholderPageSection sectionName="latestVideos" />
            ) : null}
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {dudaData['Podcast Slice'][0] === PageSliceOptions.On ? (
              <BranchPlaceholderPageSection sectionName="podcastSlice" />
            ) : null}
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default BranchTemplate;
