import { Container } from '@mui/material';
import { Subtext, TeamPhotoContainer, Title } from '../StyledComponents';
import teamPhoto from './../../../assets/images/placeholderTeamPhoto.jpg';

import { provinceToFull } from 'constants/provinceToFull';
import { Branch } from 'models/branch';

const BranchTeamPhotoHeader = ({ branch }: { branch: Branch }) => {
  return (
    <Container style={{ minWidth: 'none', width: '70%' }}>
      <Title style={{ fontSize: '60px', fontWeight: 275 }}>{`${
        branch.branchDetails.branchName ?? ''
      }`}</Title>
      <Subtext
        style={{
          fontSize: '24px',
          color: '#444444',
          marginTop: 0
        }}
      >
        {provinceToFull[branch.branchDetails.branchLocation?.province ?? '']}
      </Subtext>
      <TeamPhotoContainer>
        <img src={teamPhoto} alt="Team Photo" />
      </TeamPhotoContainer>
    </Container>
  );
};

export default BranchTeamPhotoHeader;
