import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { AdvisorHybrid } from 'models/advisor';
import Button from 'components/General/Button';
import ExpertiseCard from 'components/General/ExpertiseCard';
import Icon from 'components/General/Icon';
import ContactModal from 'components/Screens/ContactModal';
import image from 'assets/images/placeholder.svg';
import backArrow from 'assets/images/backIconWhite.svg';
import OfficeInfo from 'components/General/OfficeInfo';
import { Box } from '@mui/material';
import { Divider } from 'components/General/StyledComponents';
import BranchConsultantCardTeamPhoto from 'components/General/branch/BranchConsultantCardTeamPhoto';
import IPCSlice from 'components/General/Advisor/IPCSlice';
import PWMSSlice from 'components/General/Advisor/PWMSSlice';
import PodcastsSlice from 'components/General/Advisor/PodcastsSlice';
import { getField } from './ResultsScreen';
import FeaturedVideosSlice from 'components/General/Advisor/FeaturedVideosSlice';
import CustomContentSlice from 'components/General/Advisor/CustomContentSlice';
import ContactDetails from 'components/General/Advisor/ContactDetails';
import StartConversationBtn from 'components/General/Advisor/StartConversationBtn';
import getCollection, {
  AdvisorPageSettingsData,
  AdvisorSettingColumns,
  DudaCollectionNames,
  PageSliceOptions
} from 'queries/collectDudaSettings';

const AdvisorScreen: React.FC<{
  selectedAdvisor: AdvisorHybrid;
  goBack: () => void;
}> = ({ selectedAdvisor, goBack }) => {
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [dudaSettings, setDudaSettings] =
    useState<AdvisorPageSettingsData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.getElementById('advisorfinder-appended-div')?.scrollIntoView();
  }, []);

  React.useEffect(() => {
    getDudaSettings();
  }, []);

  const getDudaSettings = async () => {
    setLoading(true);
    const res = await getCollection(
      'cf7174f4',
      DudaCollectionNames.AdvisorPageSettings,
      selectedAdvisor.advisorFields.locatorSlug
    );
    setDudaSettings(res as AdvisorPageSettingsData | null);
    setLoading(false);
  };

  const renderSlice = React.useCallback(
    (slice: React.ReactNode | null, dudaSettingName: AdvisorSettingColumns) => {
      if (!dudaSettings) return slice;

      if (
        dudaSettings[dudaSettingName] &&
        (dudaSettings[dudaSettingName] || [])[0] === PageSliceOptions.Off
      ) {
        return null;
      }

      return slice;
    },
    [dudaSettings]
  );

  if (loading)
    return (
      <Container>
        <Box py="100px" />
      </Container>
    );

  return (
    <Container>
      <HeroContainer>
        <HeroDetails>
          <HeroColumn>
            <PhotoInfo>
              <Photo
                alt={`${getField(selectedAdvisor, 'name')}'s photograph`}
                src={selectedAdvisor.advisorFields.photo || image}
              />
            </PhotoInfo>
          </HeroColumn>
          <HeroColumnDouble>
            <PersonInfo>
              <Controls>
                <Button
                  type="button"
                  text="BACK"
                  style={{
                    color: 'rgb(255, 255, 255)',
                    fontFamily: 'Gill Sans',
                    fontSize: '18px',
                    fontWeight: 400,
                    lineHeight: '29.7px',
                    letterSpacing: '0.07em',
                    textAlign: 'left',
                    justifyContent: 'space-between',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: '4px',
                    height: '39px',
                    width: 'fit-content',
                    paddingRight: '12px'
                  }}
                  icon={<Icon alt="Back Arrow" image={backArrow} />}
                  handleClick={goBack}
                />
              </Controls>
              <HeroText>
                {selectedAdvisor.advisorFields.name}{' '}
                <Title>
                  {selectedAdvisor.advisorFields.title}
                  <Designation>
                    {' '}
                    {selectedAdvisor.advisorFields.designation}
                  </Designation>
                </Title>
              </HeroText>
              <div
                style={{
                  backgroundColor: 'rgba(131, 175, 92, 1)',
                  width: 'fit-content'
                }}
              >
                <StartConversationBtn
                  setActive={setModalActive}
                  selectedAdvisor={selectedAdvisor}
                />
              </div>
            </PersonInfo>
          </HeroColumnDouble>
        </HeroDetails>
        <Box
          sx={{
            display: 'flex',
            width: '80%',
            justifyContent: 'space-between'
          }}
        >
          <HeroColumnDouble style={{ width: '63%' }}>
            <SmallTitle style={{ marginTop: 12 }}>My Expertise</SmallTitle>
            <Divider
              style={{
                backgroundColor: 'rgba(131, 175, 92, 1)',
                margin: '0 0 12px'
              }}
            />
            <ExpertiseContainer>
              {selectedAdvisor?.advisorFields?.expertise
                ? selectedAdvisor?.advisorFields?.expertise.map(
                    (card, index) => {
                      return (
                        <ExpertiseCard text={card} key={`${index}-${card}`} />
                      );
                    }
                  )
                : null}
            </ExpertiseContainer>
            <Text>{getField(selectedAdvisor, 'descriptionParagraph')}</Text>
          </HeroColumnDouble>
          <HeroColumn style={{ width: '30%' }}>
            <Box sx={{ mt: '12px' }}>
              <ContactDetails selectedAdvisor={selectedAdvisor} />
            </Box>
          </HeroColumn>
        </Box>
      </HeroContainer>
      <IPCSlice />
      <PWMSSlice />
      {renderSlice(
        selectedAdvisor.advisorFields.latestVideosAdvisorFinderSlice ? (
          <FeaturedVideosSlice
            src={selectedAdvisor.advisorFields.latestVideosAdvisorFinderSlice}
          />
        ) : null,
        AdvisorSettingColumns.LatestVideo
      )}
      {renderSlice(
        selectedAdvisor.advisorFields.advisorFinderCustomSliceTitle ? (
          <CustomContentSlice
            title={selectedAdvisor.advisorFields.advisorFinderCustomSliceTitle}
            subtitle={
              selectedAdvisor.advisorFields.advisorFinderCustomSliceSubtitle
            }
            bulletPoints={
              selectedAdvisor.advisorFields.advisorFinderCustomSliceBulletPoints
            }
            image={selectedAdvisor.advisorFields.advisorFinderCustomSliceImage}
          />
        ) : null,
        AdvisorSettingColumns.CustomContent
      )}
      {renderSlice(
        selectedAdvisor.advisorFields.podcastAdvisorFinderSlice ? (
          <PodcastsSlice
            src={selectedAdvisor.advisorFields.podcastAdvisorFinderSlice}
          />
        ) : null,
        AdvisorSettingColumns.Podcasts
      )}
      {/* Advisor Stream Slice */}
      <Team>
        <TeamTitle>Our Team</TeamTitle>
        <TeamList>
          {selectedAdvisor?.teamFields?.advisorTeam &&
          selectedAdvisor?.teamFields?.advisorTeam.length > 0
            ? selectedAdvisor.teamFields.advisorTeam.map((teamMember) => {
                return (
                  <BranchConsultantCardTeamPhoto
                    key={`${teamMember.name}`}
                    consultant={{
                      photo: teamMember.photo,
                      name: teamMember.name,
                      title: teamMember.title
                    }}
                  />
                );
              })
            : null}
        </TeamList>
      </Team>
      <OfficeInfo
        props={{
          branchName: getField(selectedAdvisor, 'friendlyTeamName'),
          address: getField(selectedAdvisor, 'address'),
          address2: '',
          city: getField(selectedAdvisor, 'city'),
          province: getField(selectedAdvisor, 'province'),
          postalCode: getField(selectedAdvisor, 'postalCode'),
          phone: getField(selectedAdvisor, 'phone'),
          website: getField(selectedAdvisor, 'website'),
          officeHours: {
            Sunday: selectedAdvisor.advisorFields.primaryOffice
              ? selectedAdvisor.advisorFields.primaryOffice.officeHours?.Sunday
              : selectedAdvisor.teamFields.officeHours?.Sunday,
            Monday: selectedAdvisor.advisorFields.primaryOffice
              ? selectedAdvisor.advisorFields.primaryOffice.officeHours?.Monday
              : selectedAdvisor.teamFields.officeHours?.Monday,
            Tuesday: selectedAdvisor.advisorFields.primaryOffice
              ? selectedAdvisor.advisorFields.primaryOffice.officeHours?.Tuesday
              : selectedAdvisor.teamFields.officeHours?.Tuesday,
            Wednesday: selectedAdvisor.advisorFields.primaryOffice
              ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                  ?.Wednesday
              : selectedAdvisor.teamFields.officeHours?.Wednesday,
            Thursday: selectedAdvisor.advisorFields.primaryOffice
              ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                  ?.Thursday
              : selectedAdvisor.teamFields.officeHours?.Thursday,
            Friday: selectedAdvisor.advisorFields.primaryOffice
              ? selectedAdvisor.advisorFields.primaryOffice.officeHours?.Friday
              : selectedAdvisor.teamFields.officeHours?.Friday,
            Saturday: selectedAdvisor.advisorFields.primaryOffice
              ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                  ?.Saturday
              : selectedAdvisor.teamFields.officeHours?.Saturday
          }
        }}
        style={{ marginBottom: 100 }}
      />
      <ContactModal
        modalActive={modalActive}
        setModalActive={setModalActive}
        teamName={selectedAdvisor.advisorFields.name}
        advisorRecipient={selectedAdvisor.advisorFields.email}
      />
    </Container>
  );
};

const Container = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 150px;
  @media screen and (max-width: 980px) {
    padding-top: 90px;
  }
`;

const TeamTitle = styled.div`
  font-family: Poppins;
  font-size: 32px;
  font-weight: 275;
  line-height: 52.8px;
  text-align: center;
  color: rgb(0, 0, 0);
  margin-top: 60px;
  margin-bottom: 20px;
`;

const Controls = styled.div`
  display: flex;
  @media screen and (max-width: 767px) {
    padding: 10px 0px;
    margin: 0px 15px;
    margin-right: auto;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    padding: 10px 0px;
  }
`;

const HeroContainer = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  background: linear-gradient(71.93deg, #232926 1.4%, #006331 99.43%);
  width: 100%;
  min-height: 536px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 767px) {
    padding-bottom: 30px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    min-height: 400px;
  }
`;

const HeroDetails = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 80%;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: 90%;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    width: 90%;
  }
  padding-bottom: 30px;
`;

const HeroColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  @media screen and (max-width: 767px) {
    width: 100%;
    justify-content: center;
  }
`;

const HeroColumnDouble = styled.div`
  display: flex;
  flex-direction: column;
  width: 66%;
  @media screen and (max-width: 767px) {
    width: 100%;
    justify-content: center;
  }
`;

const PersonInfo = styled.div`
  padding: 30px 30px 30px 0px;
  min-height: 300px;
  height: 100%;
  flex-direction: column;
  display: flex;
  padding: 0px 10px 0px 20px;
  justify-content: space-evenly;
  @media screen and (max-width: 767px) {
    padding: 10px 0px 10px 0px;
    min-height: 260px;
    align-items: center;
    margin: 0px;
  }
`;

const ExpertiseContainer = styled.div`
  display: flex;
  margin-bottom: 0px;
  height: auto;
  width: 100%;
  flex-wrap: wrap;
`;

/* Text */

export const SmallTitle = styled.h3`
  color: rgb(255, 255, 255);
  font-family: Poppins;
  font-weight: 200;
  font-size: 32px;
  line-height: 135%;
  width: 90%;
  margin: 0px;
  margin-bottom: 10px;
  @media screen and (max-width: 767px) {
    font-size: 25px;
    line-height: 125%;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    font-size: 26px;
    line-height: 125%;
  }
`;

const Text = styled.p`
  font-size: 20px;
  line-height: 40px;
  font-family: Gill Sans;
  font-weight: 400;
  line-height: 40px;
  color: rgb(255, 255, 255);
  font-family: Gill Sans, sans-serif;
  @media screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 32px;
    padding: 20px 0px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    padding: 0px 0px;
  }
`;

const HeroText = styled.h2`
  font-size: 60px;
  text-transform: none !important;
  text-align: left;
  padding: 0px;
  line-height: 60px;
  letter-spacing: -1px;
  margin: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 200;
  color: rgb(255, 255, 255);
  margin-top: 5px;
  @media screen and (max-width: 767px) {
    font-size: 32px;
    text-align: center;
    margin-top: 0px;
    line-height: 42px;
    padding: 0px 0px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    font-size: 36px;
  }
  line-height: 44px;
`;

const PhotoInfo = styled.div`
  display: flex;
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
`;

const Title = styled.p`
  font-family: Gill Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 33px;
  text-align: left;
  color: rgb(255, 255, 255);
  letter-spacing: 0.5px;
  margin: 0px;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
  font-weight: 600;
  font-size: 20px;
  margin-top: 12px;
`;

const Designation = styled.span`
  font-size: 20px;
  font-weight: 600;
  font-family: Gill Sans;
  letter-spacing: 0.5px;
`;

/* End Text */

const Photo = styled.img`
  width: 360px;
  object-fit: cover;
  filter: drop-shadow(0px 4px 12px rgba(37, 47, 35, 0.15));
  max-width: 100%;
  height: auto;
  @media screen and (max-width: 767px) {
    width: 240px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    width: 260px;
  }
`;

const Team = styled.div`
  padding-bottom: 40px;
  @media screen and (max-width: 767px) {
    padding-bottom: 20px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    padding-bottom: 20px;
  }
`;

const TeamList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export default AdvisorScreen;
