import styled from 'styled-components';
import Button from '../Button';
import ReactPlayer from 'react-player';

const IPCSlice = () => (
  <IPCSliceContainer>
    <IPCSliceInner>
      <ReactPlayer
        style={{ height: '400px', width: '600px' }}
        controls
        url="https://player.vimeo.com/video/468772356"
        light={true}
      />
      <div style={{ width: '45%', padding: '50px' }}>
        <h3>Be confident in your financial future with us</h3>
        <p>
          We take the time to build deeper, more personal connections – with you
          and your family. It’s the X-factor that gives you the confidence to
          share your hopes and dreams, what drives and inspires you.
        </p>
        <p>
          So, regardless of how you choose to connect, we will get to the heart
          of your hard questions and tailor your plan to give you the answers
          you need.
        </p>
        <Button
          text="Learn more about IPC"
          type="button"
          style={{
            color: '#00462D',
            backgroundColor: '#FFFFFF',
            border: '2px solid #00462D',
            minWidth: '212px'
          }}
        />
      </div>
    </IPCSliceInner>
  </IPCSliceContainer>
);

const IPCSliceContainer = styled.div`
  background-color: #f5f8f9;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 10px;
  h3 {
    font-size: 32px;
    font-weight: 300;
    font-family: Poppins;
    margin-top: 0;
  }
  p {
    opacity: 80%;
  }
`;

const IPCSliceInner = styled.div`
  display: flex;
  max-width: 1200px;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    flex-direction: column;
  }
`;

export default IPCSlice;
