import { SmallTitle } from 'components/Screens/AdvisorScreen';
import { AdvisorHybrid } from 'models/advisor';
import styled from 'styled-components';
import { Divider } from 'components/General/StyledComponents';
import location from 'assets/images/locationPinWhite.svg';
import phoneIcon from 'assets/images/phoneWhite.svg';
import webIcon from 'assets/images/websiteWhite.svg';
import FacebookIcon from 'assets/images/facebookWhite.svg';
import LinkedInIcon from 'assets/images/linkedinWhite.svg';
import { getField } from 'components/Screens/ResultsScreen';
import { ReactGA4Event } from 'utilities/reactGA4Event';
import Icon from 'components/General/Icon';

const ContactDetails = ({
  selectedAdvisor
}: {
  selectedAdvisor: AdvisorHybrid;
}) => {
  const getAddress = () => (
    <>
      {' '}
      {getField(selectedAdvisor, 'address')}
      <br />
      {`${getField(selectedAdvisor, 'city')} ${getField(
        selectedAdvisor,
        'province'
      )} ${getField(selectedAdvisor, 'postalCode')}`}
    </>
  );

  const getURL = () =>
    `https://www.google.com/maps/search/?api=1&query=${getField(
      selectedAdvisor,
      'address'
    )}+${getField(selectedAdvisor, 'city')}+${getField(
      selectedAdvisor,
      'province'
    )}+${getField(selectedAdvisor, 'postalCode')}+IPC+Investment+Corporation`;
  return (
    <>
      <SmallTitle>Details</SmallTitle>
      <Divider
        style={{
          backgroundColor: 'rgba(131, 175, 92, 1)',
          margin: '0 0 12px'
        }}
      />
      <Info>
        <div style={{ marginBottom: '12px', display: 'flex' }}>
          <img alt="Location Icon" src={location} />
          <Address
            onClick={() => {
              ReactGA4Event('event', 'click', {
                link_category: 'find_an_advisor',
                advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                link_id: 'viewMap',
                link_text: `${getField(
                  selectedAdvisor,
                  'address'
                )} -- ${getField(selectedAdvisor, 'city')} ${getField(
                  selectedAdvisor,
                  'province'
                )} ${getField(selectedAdvisor, 'postalCode')}`,
                link_url: `${getURL()}`
              });
            }}
            id="viewMap"
            target="_blank"
            href={getURL()}
          >
            {getAddress()}
          </Address>
        </div>
        <div style={{ marginBottom: '12px' }}>
          <Icon
            handleClick={() =>
              ReactGA4Event('event', 'click', {
                link_category: 'find_an_advisor',
                advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                link_id: 'clickToCall',
                link_url: `${getField(selectedAdvisor, 'phone')}`
              })
            }
            alt=""
            image={phoneIcon}
            phone={getField(selectedAdvisor, 'phone')}
          />
        </div>
        {selectedAdvisor.advisorFields.branch?.branchSlug ? (
          <div style={{ marginBottom: '12px' }}>
            <Icon
              uniqueId="websiteClick"
              handleClick={() => {
                ReactGA4Event('event', 'click', {
                  link_category: 'branch_landing_page',
                  advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                  branch: `${selectedAdvisor.advisorFields.branch?.branchName}`,
                  link_id: 'websiteClick',
                  link_url: `${selectedAdvisor.advisorFields.branch?.branchSlug}`
                });
              }}
              alt=""
              image={webIcon}
              text={
                `Visit ${selectedAdvisor.advisorFields.branch?.branchName}'s website` ||
                ''
              }
              website={`?branchLandingSlug=${selectedAdvisor.advisorFields.branch?.branchSlug}`}
              openInNewTab={false}
            />
          </div>
        ) : (
          <div style={{ marginBottom: '12px' }}>
            <Icon
              uniqueId="branchLandingClick"
              handleClick={() =>
                ReactGA4Event('event', 'click', {
                  link_category: 'find_an_advisor',
                  advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                  link_id: 'websiteClick',
                  link_url: `${getField(selectedAdvisor, 'website')}`
                })
              }
              alt=""
              image={webIcon}
              website={getField(selectedAdvisor, 'website')}
              openInNewTab={true}
            />
          </div>
        )}

        {selectedAdvisor.advisorFields.facebook ||
        selectedAdvisor.advisorFields.linkedIn ? (
          <Socials>
            {selectedAdvisor.advisorFields.facebook ? (
              <a
                id="facebookClick"
                href={selectedAdvisor.advisorFields.facebook}
                target="_blank"
                style={{ marginRight: '10px' }}
                onClick={() => {
                  ReactGA4Event('event', 'click', {
                    link_category: 'find_an_advisor',
                    advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                    link_id: 'facebookClick',
                    link_url: `${selectedAdvisor.advisorFields.facebook}`
                  });
                }}
              >
                <img alt="Facebook Icon" src={FacebookIcon} />
              </a>
            ) : null}
            {selectedAdvisor.advisorFields.linkedIn ? (
              <a
                id="linkedinClick"
                href={selectedAdvisor.advisorFields.linkedIn}
                target="_blank"
                onClick={() => {
                  ReactGA4Event('event', 'click', {
                    link_category: 'find_an_advisor',
                    advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                    link_id: 'linkedinClick',
                    link_url: `${selectedAdvisor.advisorFields.linkedIn}`
                  });
                }}
              >
                <img alt="LinkedIn Icon" src={LinkedInIcon} />
              </a>
            ) : null}
          </Socials>
        ) : null}
      </Info>
    </>
  );
};

const Info = styled.div`
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  box-shadow: 0px 4px 12px rgba(37, 47, 35, 0.15);
  @media screen and (max-width: 767px) {
    min-height: 160px;
    padding: 20px;
    justify-content: space-between;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    min-height: 210px;
  }
`;

const Address = styled.a`
  margin: 0px;
  margin-left: 12px;
  color: rgb(255, 255, 255);
  text-decoration: auto;
  font-family: Gill Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.7px;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
  &:hover {
    cursor: pointer;
  }
`;

const Socials = styled.div`
  display: flex;
  justify-content: space-between;
  width: 25%;
  align-items: center;
`;

export default ContactDetails;
