import React from 'react';
import styled from 'styled-components';

import image from 'assets/images/placeholder.svg';
import Button from 'components/General/Button';

import { Box } from '@mui/material';
import { TeamMemberDetails } from 'models/branch';
import AdvisorBioModal from './AdvisorBioModal';

const BranchAdvisorCardTeamPhoto: React.FC<{
  advisor: TeamMemberDetails;
}> = ({ advisor }) => {
  const [bioModalOpen, setBioModalOpen] = React.useState(false);

  const redirectToAdvisorPage = () => {
    const slug = advisor.locatorSlug?.split('/').pop();

    if (!slug) return;

    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('advisorLocatorSlug', slug);

    window.location.href = newUrl.toString();
  };

  return (
    <BranchAdvisorCardContainer>
      <PhotoContainer>
        <img src={advisor.photo || image} alt="Advisor" />
      </PhotoContainer>
      <AdvisorDetailsContainer>
        <AdvisorName>{advisor.name}</AdvisorName>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <AdvisorTitle>
            {advisor.title}
            {advisor.designation ? `, ${advisor.designation}` : ''}
          </AdvisorTitle>
        </Box>
        {advisor.enableAdvisorLocator ? (
          <Button
            text="Learn More"
            type="button"
            style={{
              border: '1px solid rgba(0, 70, 45, 1)',
              backgroundColor: 'rgb(255,255,255)',
              color: 'rgba(0, 98, 38, 1)'
            }}
            handleClick={redirectToAdvisorPage}
          />
        ) : (
          <Button
            text="Read Bio"
            type="button"
            style={{
              border: '1px solid rgba(0, 70, 45, 1)',
              backgroundColor: 'rgb(255,255,255)',
              color: 'rgba(0, 98, 38, 1)'
            }}
            handleClick={() => setBioModalOpen(true)}
          />
        )}
      </AdvisorDetailsContainer>
      <AdvisorBioModal
        advisor={advisor}
        open={bioModalOpen}
        onClose={() => setBioModalOpen(false)}
      />
    </BranchAdvisorCardContainer>
  );
};

const BranchAdvisorCardContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  margin: 10px;
`;

const AdvisorDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
`;

const AdvisorTitle = styled.p`
  font-family: Gill Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 20.25px;
  letter-spacing: -0.02em;
  text-align: left;
  color: rgba(88, 89, 91, 1);
`;

const AdvisorName = styled.h3`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 300;
  line-height: 24.3px;
  letter-spacing: -0.02em;
  text-align: left;
  margin: 0px;
`;

const PhotoContainer = styled.div`
  width: 360px;
  height: 250px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default BranchAdvisorCardTeamPhoto;
