import { createClient } from 'contentful';
import { ContentfulResponse } from 'models/contentfulResponse';
import { constructAdvisorHybrid } from './helpers/constructAdvisorHybrid';

const collectSpecificAdvisor = async (locatorSlug: string) => {
  const contentfulClient = createClient({
    accessToken: `${process.env.REACT_APP_CONTENTFUL_SECRET_TOKEN}`,
    space: `${process.env.REACT_APP_CONTENTFUL_SPACE_ID}`,
    environment: `${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}`
  });
  const primaryOfficeArray: any[] = [];
  const teamMemberIDs = await contentfulClient
    .getEntries({
      'fields.locatorSlug[match]': locatorSlug,
      content_type: 'teamMember',
      limit: 1,
      select: ['fields.name', 'fields.primaryOffice']
    })
    .then((entries: any) => {
      const allAdvisors: string[] = [];
      entries.items.forEach((advisorName: ContentfulResponse) => {
        if (advisorName.fields.primaryOffice) {
          primaryOfficeArray.push(advisorName.fields.primaryOffice);
        }
        allAdvisors.push(advisorName.sys.id);
      });

      return allAdvisors;
    });

  const advisorHybrid = await constructAdvisorHybrid(
    teamMemberIDs,
    primaryOfficeArray
  );

  return advisorHybrid[0];
};

export default collectSpecificAdvisor;
