import { EntryCollection, createClient } from 'contentful';
import {
  Branch,
  ContentfulBranchResponse,
  ContentfulTeamMemberResponse
} from 'models/branch';
import * as Sentry from '@sentry/react';

const collectSpecificBranch = async (
  branchSlug: string
): Promise<Branch | undefined> => {
  try {
    const contentfulEnvironemnt = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT;

    const contentfulClient = createClient({
      accessToken: `${process.env.REACT_APP_CONTENTFUL_SECRET_TOKEN}`,
      space: `${process.env.REACT_APP_CONTENTFUL_SPACE_ID}`,
      environment: `${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}`
    });

    const branchResponse: EntryCollection<ContentfulBranchResponse> =
      await contentfulClient.getEntries({
        'fields.branchSlug': branchSlug,
        content_type: 'branch',
        limit: 1,
        include: 2
      });
    const branch = branchResponse.items[0];

    if (!branch) {
      throw new Error(`Branch with branchSlug ${branchSlug} not found`);
    }

    // In contentful stage location is and array of objects
    // In contentful master location is an object
    const branchLocation = branch.fields.officeLocation?.fields;

    const branchDetails = {
      branchName: branch.fields.branchName,
      branchLocation: branchLocation,
      branchSlug: branch.fields.branchSlug
        ? `advisors.ipcc.ca/${branch.fields.branchSlug}`
        : undefined,
      branchIntro: branch.fields.branchIntro,
      branchEdits: branch.fields.branchEdits,
      id: branch.sys.id
    };
    const teamMembersId = branch.fields.advisors
      ?.map((advisor) =>
        advisor.fields?.teamMembers
          ?.map((teamMember) => teamMember.sys.id)
          .join(',')
      )
      .join(',');

    if (!teamMembersId) {
      return {
        branchDetails,
        teamMembers: [],
        consultants: []
      };
    }

    const teamMembersResponse: EntryCollection<ContentfulTeamMemberResponse> =
      await contentfulClient.getEntries({
        'sys.id[in]': teamMembersId,
        content_type: 'teamMember',
        limit: 1000,
        select: [
          'fields.name',
          'fields.title',
          'fields.designation',
          'fields.description',
          'fields.adviceYourWayAdvisor',
          'fields.typeOfAdvisor',
          'fields.email',
          'fields.linkedIn',
          'fields.facebook',
          'fields.bookAnAppointment',
          contentfulEnvironemnt === 'master'
            ? 'fields.expertise'
            : 'fields.yourExpertise',
          'fields.teamMemberEdits',
          'fields.image',
          contentfulEnvironemnt === 'master'
            ? 'fields.enableAdvisorLocator'
            : 'fields.enableInAdvisorLocator',
          'fields.locatorSlug'
        ]
      });

    const teamMembers =
      teamMembersResponse.items
        .filter(
          (teamMember) => teamMember.fields?.enableAdvisorLocator === true
        )
        .map((teamMember) => ({
          id: teamMember.sys.id,
          ...teamMember.fields
        })) ?? [];

    const consultantsId = branch.fields.advisors
      ?.map((advisor) =>
        advisor.fields.consultants
          ?.map((consultant) => consultant.sys.id)
          .join(',')
      )
      .join(',');

    const consultantsResponse: EntryCollection<ContentfulTeamMemberResponse> =
      await contentfulClient.getEntries({
        'sys.id[in]': consultantsId,
        content_type: 'teamMember',
        limit: 1000,
        select: [
          'fields.name',
          'fields.designation',
          'fields.title',
          'fields.description',
          'fields.adviceYourWayAdvisor',
          'fields.typeOfAdvisor',
          'fields.email',
          'fields.linkedIn',
          'fields.facebook',
          'fields.bookAnAppointment',
          contentfulEnvironemnt === 'master'
            ? 'fields.expertise'
            : 'fields.yourExpertise',
          'fields.teamMemberEdits',
          'fields.image',
          contentfulEnvironemnt === 'master'
            ? 'fields.enableAdvisorLocator'
            : 'fields.enableInAdvisorLocator',
          'fields.locatorSlug'
        ]
      });

    const consultants =
      consultantsResponse.items
        .filter(
          (teamMember) => teamMember.fields?.enableAdvisorLocator === true
        )
        .map((teamMember) => ({
          id: teamMember.sys.id,
          ...teamMember.fields
        })) ?? [];
    return {
      branchDetails,
      branchResponse,
      teamMembers: teamMembers.map((teamMember) => ({
        name: teamMember.name,
        title: teamMember.title,
        designation: teamMember.designation,
        description: teamMember.description?.content?.[0]?.content?.[0]?.value,
        email: teamMember.email,
        linkedIn: teamMember.linkedIn,
        facebook: teamMember.facebook,
        bookAnAppointment: teamMember.bookAnAppointment,
        yourExpertise: teamMember.expertise?.join(', '),
        typeOfAdvisor: teamMember.typeOfAdvisor,
        teamMemberEdits: teamMember.teamMemberEdits,
        photo: teamMember?.image?.fields?.file?.url,
        locatorSlug: teamMember.locatorSlug
          ? `advisors.ipcc.ca/${teamMember.locatorSlug}`
          : undefined,
        enableAdvisorLocator: teamMember.enableAdvisorLocator,
        id: teamMember.id
      })),
      consultants: consultants.map((consultant) => ({
        name: consultant.name,
        title: consultant.title,
        designation: consultant.designation,
        description: consultant.description?.content?.[0]?.content?.[0]?.value,
        email: consultant.email,
        linkedIn: consultant.linkedIn,
        facebook: consultant.facebook,
        bookAnAppointment: consultant.bookAnAppointment,
        yourExpertise: consultant.expertise?.join(', '),
        typeOfAdvisor: consultant.typeOfAdvisor,
        teamMemberEdits: consultant.teamMemberEdits,
        photo: consultant?.image?.fields?.file?.url,
        locatorSlug: consultant.locatorSlug
          ? `advisors.ipcc.ca/${consultant.locatorSlug}`
          : undefined,
        id: consultant.id
      }))
    };
  } catch (error) {
    Sentry.captureException(error);
  }
};

export default collectSpecificBranch;
